<template>
  <div class="illegally-info">
    <div class="item">
      <div class="title">违停时间</div>
      <div class="time">{{time}}</div>
    </div>
    <div class="item">
      <div class="title">违停描述</div>
      <div class="des">{{content}}</div>
    </div>
    <div class="item" style="height: 100%;">
      <div class="title">上传图片</div>
      <upload-file
        :readonly="true"
        :file-list="fileList"
        :delBtnShow="false"
        :uploadBtnShow="false"
      />
    </div>
  </div>
</template>

<script>
import uploadFile from '@/components/upload/uploadFile'
export default {
  data() {
    return {
      // id: '',
      time: '',
      content: '',
      fileList: []
    }
  },
  components: {
    uploadFile
  },
  created() {
    this.getInfo(this.$route.query.id)
  },
  methods: {
    getInfo(id) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/device/car/mistake/info'),
        method: 'GET',
        params: this.$http.adornParams({id})
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.time = data.deviceCarMistake.mistakeTime
          this.content = data.deviceCarMistake.content || '无违停描述'
          if(data.deviceCarMistake.deviceCarMistakeFileList.length > 0) {
            this.fileList = data.deviceCarMistake.deviceCarMistakeFileList.map(item => {
              return {
                ...item,
                id: item.id,
                fileType: item.fileType,
                relativePath: item.filePath,
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.illegally-info {
  background: #FFFFFF;
  padding: 0 30px;
  .item {
    width: 100%;
    min-height: 146px;
    padding: 24px 0;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    border-bottom: 1px solid #EDEDED;
    &:nth-last-child(1) {
      border: none;
    }
    .title {
      font-size: 30px;
      color: #666666;
      margin-bottom: 12px;
    }
    .time {
      font-size: 34px;
      color: #E9564B;
    }
    .des {
      font-size: 34px;
      color: #333333;
    }
  }
}
</style>